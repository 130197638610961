<!-- Template -->
<template>
  <div class="o-step-menu">
    <section class="o-step-menu__actions">
      <button 
        class="o-step-menu__actions__back" 
        type="button" 
        @click="() => handleClickReturnContext('back')"
      ><IconChevronLeft /></button>

      <div class="o-step-menu__actions__separator" />

      <button 
        class="o-step-menu__actions__logo" 
        type="button" 
        @click="() => handleClickReturnContext('home')"
      ><IconConsignado /></button>
    </section>

    <section class="o-step-menu__info">
      <h2 class="o-step-menu__info__title">
        Empréstimo<br />Consignado
      </h2>

      <h4 class="o-step-menu__info__subtitle">
        <b>Seguro</b> e <b>100% digital</b>
      </h4>
    </section>

    <section v-if="!isOutOfBounds" class="o-step-menu__steps">
      <h4 class="o-step-menu__steps__title">Etapas:</h4>

      <AtomStepDisplay 
        class="o-step-menu__steps__steps" 
        :current-step="currentStep" 
        :step-list="stepList"
      />
    </section>
  </div>
</template>

<!-- Scripts -->
<script>
// Icones externos
import IconChevronLeft from '~/assets/icons/IconChevronLeft.vue'
import IconConsignado from '~/assets/icons/IconConsignado.vue'

// Componentes externos
import AtomStepDisplay from '../../atoms/AtomStepDisplay/AtomStepDisplay.vue'

// Componente
export default {
  // Nome do componente
  name: 'OrganismStepMenu',
  // Componentes importados
  components: {
    // Icones
    IconChevronLeft,
    IconConsignado,
    // Componentes
    AtomStepDisplay,
  },
  // Propriedades herdadas
  props: {
    currentStep: { type: Number, default: 1 },
    stepList: { type: Array, default: [] },
  },
  // Dados computados
  computed: {
    isOutOfBounds() {
      const currentStep = this.currentStep
      const stepList = [...this.stepList]
      return !stepList.length || currentStep < stepList[0].id || currentStep > stepList[stepList.length - 1].id
    },
  },
  // Metodos
  methods: {
    handleClickReturnContext(value) {
      this.$store.dispatch('setContextReturn', value)
    },
  },
}
</script>

<!-- Estilos -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.o-step-menu {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr 2fr;
  row-gap: 72px;
  padding: 48px 40px;

  @media screen and (max-width: 1200px) {
    grid-template-rows: auto;
    row-gap: 32px;
    padding: 0;
  }

  &__actions {
    display: grid;
    grid-auto-flow: column;
    place-content: start;
    place-items: center;

    @media screen and (max-width: 1200px) {
      grid-template-columns: auto 1fr;
      padding: 32px 24px;
      @include box-shadow();
      background-color: $alfa-brand-primary;
    }

    &__back {
      cursor: pointer;
      background-color: transparent;
      border: 0;
    }

    &__separator {
      width: 2px;
      height: 80%;
      margin-left: 40px;
      margin-right: 24px;
      background-color: $alfa-support-1;
      opacity: 0.2;

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }

    &__logo {
      cursor: pointer;
      background-color: transparent;
      border: 0;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    place-items: center start;
    row-gap: 40px;

    @media screen and (max-width: 1200px) {
      display: none;
    }

    &__title {
      font-family: 'Montserrat Bold';
      font-size: clamp(1.125rem, 1.563rem, 1.563rem);
      line-height: clamp(1.925rem, 2.363rem, 2.263rem);
      color: $alfa-support-1;
      text-transform: uppercase;
    }

    &__subtitle {
      position: relative;
      font-family: 'Montserrat';
      font-size: clamp(1rem, 1.25rem, 1.25rem);
      color: $alfa-support-1;

      b {
        font-family: 'Montserrat SemiBold';
        color: $alfa-brand-secondary;
      }

      &::after {
        position: absolute;
        left: 0;
        bottom: -8px;
        content: '';
        width: 110%;
        height: 4px;
        background-color: $alfa-brand-tertiary;
        border-radius: 80px;
        box-shadow: 0 3px 6px #00000029;
      }
    }
  }

  &__steps {
    display: grid;
    grid-template-columns: 1fr;
    place-content: start;
    row-gap: 24px;

    @media screen and (max-width: 1200px) {
      place-content: stretch;
      place-items: center;
    }

    &__title {
      font-family: 'Montserrat Medium';
      font-size: clamp(0.875rem, 1rem, 1rem);
      color: $alfa-support-1;

      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
}
</style>