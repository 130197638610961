<!-- Template -->
<template>
  <ul class="a-step-display">
    <li
      v-for="(stepItem, index) in stepList"
      :key="stepItem.id"
      class="a-step-display__step"
      :class="[
        { 'a-step-display__step--current': currentStep === stepItem.id },
        { 'a-step-display__step--complete': currentStep > stepItem.id },
        { 'a-step-display__step--incomplete': currentStep < stepItem.id },
      ]"
    >
      <span class="a-step-display__step__button" type="button">
        <IconCheck v-if="currentStep > stepItem.id" />
        <template v-else>
          {{ stepItem.label }}
        </template>
      </span>
      <template v-if="index !== stepList.length - 1">
        <span class="a-step-display__step__dot" v-for="e in 2" :key="`${stepItem.id}-${e}`"> &#10625; </span>
      </template>
    </li>
  </ul>
</template>

<!-- Scripts -->
<script>
// Icones externos
import IconCheck from '~/assets/icons/IconCheck.vue'

// Componente
export default {
  // Nome do componente
  name: 'AtomStepDisplay',
  // Componentes importados
  components: {
    // Icones
    IconCheck,
  },
  // Propriedades herdadas
  props: {
    currentStep: { type: Number, required: true },
    stepList: { type: Array, required: true },
  },
}
</script>

<!-- Estilos -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.a-step-display {
  display: grid;
  grid-auto-flow: column;
  place-content: center start;
  place-items: center;
  column-gap: 10px;

  &__step {
    display: grid;
    grid-template-columns: 30px 5px 5px;
    grid-template-rows: 30px;
    place-content: center;
    place-items: center;
    column-gap: 10px;

    &:last-of-type {
      grid-template-columns: 30px;
      column-gap: 0;
    }

    &__button {
      display: grid;
      place-content: center;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 1px solid $alfa-brand-tertiary;
      font-family: 'Montserrat SemiBold';
      font-size: clamp(0.75rem, 0.875rem, 0.875rem);

      @media screen and (max-width: 1200px) {
        border-color: $alfa-brand-primary;
      }
    }

    &--current &__button {
      background-color: $alfa-brand-tertiary;

      @media screen and (max-width: 1200px) {
        color: $alfa-brand-tertiary;
        background-color: $alfa-brand-primary;
      }
    }

    &--complete &__button {
      background-color: transparent;
    }

    &--incomplete &__button {
      color: $alfa-brand-tertiary;
      background-color: transparent;

      @media screen and (max-width: 1200px) {
        color: $alfa-brand-primary;
      }
    }

    &__dot {
      color: $alfa-brand-tertiary;
      opacity: 0.5;

      @media screen and (max-width: 1200px) {
        color: $alfa-brand-primary;
      }
    }
  }
}
</style>
