<!-- Template -->
<template>
  <div class="l-simulator">
    <aside class="l-simulator__aside">
      <div class="l-simulator__aside__container">
        <OrganismStepMenu :current-step="currentStep" :step-list="stepList" />
      </div>
    </aside>

    <main class="l-simulator__main">
      <div class="l-simulator__main__container" ref="scroll">
        <slot></slot>
      </div>
    </main>
    <AtomLoading :is-active="loading" />
    <OrganismCookieManager />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import OrganismStepMenu from '~/components/transational/organisms/OrganismStepMenu/OrganismStepMenu.vue'
import AtomLoading from '@/components/atoms/AtomLoading/AtomLoading.vue'
import OrganismCookieManager from '~/components/institutional/organisms/OrganismCookieManager/OrganismCookieManager.vue'

export default {
  name: 'SimulatorLayout',
  components: {
    OrganismStepMenu,
    AtomLoading
  },
  computed: {
    ...mapState({
      loading: (state) => state.loading,
      currentStep: (state) => state.currentStep,
      stepList: (state) => state.stepList,
      scrollToTop: (state) => state.scrollToTop,
    })
  },
  watch: {
    scrollToTop(newValue) {
      if (newValue) {
        const scrollRef = this.$refs.scroll
        scrollRef.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
        
        setTimeout(() => {
          this.$store.dispatch('setScrollToTop', false)
        }, 250)
      }
    }
  }
}
</script>

<!-- Estilos -->
<style lang="scss" scoped>
@import '~/assets/scss/transational/bootstrap.scss';

.l-simulator {
  display: grid;
  grid-template-columns: minmax(min-content, 400px) 1fr;
  grid-template-rows: 1fr;
  place-content: start center;
  width: 100vw;
  height: 100svh;
  background-color: $alfa-brand-primary;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
    background-color: $alfa-support-1;
  }

  &__aside {
    display: grid;
    place-self: stretch;
    max-height: 100svh;

    @media screen and (max-width: 1200px) {
      padding-bottom: 16px;
    }

    &__container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
      overflow: hidden;
    }
  }

  &__main {
    display: grid;
    place-self: stretch;
    place-content: stretch;
    padding-top: 16px;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
      padding: 0;
    }

    &__container {
      display: grid;
      place-content: stretch;
      overflow: hidden auto;
      padding: 40px;
      border-radius: 5px 0 0 0;
      @include scrollbar();
      @include scrollbox-shadow();

      @media screen and (max-width: 860px) {
        padding: 40px 32px 40px;
      }

      @media screen and (max-width: 630px) {
        padding: 40px 24px 40px;
      }
    }
  }
}
</style>
